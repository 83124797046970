import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";
import ProductPage from "./ProductPage";
import productImg from '../assets/Eyes Tee 2.png'
import eyesGraphic from '../assets/EYES_OUTLINE_GRAPHIC.png'

const EyesTeePage = () => (
    <section>
        <MediaQuery minWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                    backgroundImage: `url(${eyesGraphic})`,
                    backgroundSize: "cover", // Adjust how the image fits the background
                    backgroundSize: '100%',
                    backgroundPosition: "center", // Centers the image
                    backgroundRepeat: "no-repeat", // Prevents tiling
                    width: "100%",
                    height: "100%",
                    top: "0",
                    overflowX: "hidden",
                    overflowY: "scroll",
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '100%',
                height: '35px',
                // border: '1px solid green',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Link to="/store">
                <button style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'Press',
                    marginBottom: '20px',
                    }}>BACK
                </button>
                </Link>
            </div>
            <ProductPage
                title="EYES TEE"
                description="EYES TEE"
                img={productImg}
                smallLink="https://buy.stripe.com/aEU8xrbN4bpK2Ri14t"
                mediumLink="https://buy.stripe.com/7sI7tn2cu51m1NeeVi"
                largeLink="https://buy.stripe.com/bIY00V18q3XibnO14r"
                xlargeLink="https://buy.stripe.com/fZe8xrg3k65q9fGeVg"
                xxlargeLink="https://buy.stripe.com/aEUeVP04mgK4gI8cN7"
            ></ProductPage>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={500}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                    backgroundImage: `url(${eyesGraphic})`,
                    backgroundSize: "cover", // Adjust how the image fits the background
                    backgroundSize: '100%',
                    backgroundPosition: "center", // Centers the image
                    backgroundRepeat: "no-repeat", // Prevents tiling
                    width: "100%",
                    height: "100%",
                    top: "0",
                    overflowX: "hidden",
                    overflowY: "scroll",
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <div style={{
                width: '100%',
                height: '35px',
                // border: '1px solid green',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '-15px'
            }}>
                <Link to="/store">
                <button style={{
                    height: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '8px',
                    fontFamily: 'Press',
                    marginBottom: '-20px',
                    }}>BACK
                </button>
                </Link>
            </div>
            <ProductPage
                title="EYES TEE"
                description="EYES TEE"
                img={productImg}
                smallLink="https://buy.stripe.com/aEU8xrbN4bpK2Ri14t"
                mediumLink="https://buy.stripe.com/7sI7tn2cu51m1NeeVi"
                largeLink="https://buy.stripe.com/bIY00V18q3XibnO14r"
                xlargeLink="https://buy.stripe.com/fZe8xrg3k65q9fGeVg"
                xxlargeLink="https://buy.stripe.com/aEUeVP04mgK4gI8cN7"
            ></ProductPage>
            </div>
        </MediaQuery>
    </section>
);

export default EyesTeePage;