import {React} from "react";
import "../App.css";
import '../index.css';
import Header from "./Header";
import SpinningLogo from "./SpinningLogo";
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";
// import ProductPage from "./ProductPage";
import productImg from '../assets/OG Tee.png'
import productImg2 from '../assets/glow_thumbnail_v1.png'
import productImg3 from '../assets/Clash Mag Tee.png'
import productImg4 from '../assets/Eyes Tee 2.png'
import eyesGraphic from '../assets/EYES_OUTLINE_GRAPHIC.png'

const Store = () => (
    <section>
        <MediaQuery minWidth={768}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                    backgroundImage: `url(${eyesGraphic})`,
                    backgroundSize: "cover", // Adjust how the image fits the background
                    backgroundSize: '70%',
                    backgroundPosition: "center", // Centers the image
                    backgroundRepeat: "no-repeat", // Prevents tiling
                    width: "100%",
                    height: "100%",
                    top: "0",
                    overflowX: "hidden",
                    overflowY: "scroll",
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '24px',
                textAlign: 'center',
                fontFamily: 'Eurostile',
                fontWeight: '700',
                lineHeight: '30px',
                whiteSpace: 'pre-wrap'
            }}>
                STORE<br></br><span style={{color: 'red', fontSize: '14px'}}>[1] EXCLUSIVE ITEM ADDED</span>
            </h1>
            <div style={{
                display: 'flex',
                width: '100%',
                alignContent: 'center',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                columnGap: '100px',
                margin: 'auto',
                // border: '1px solid green'
            }}>
                <div style={{
                    display: 'block',
                    width: '280px',
                    // border: '1px solid green',
                    paddingRight: '40px'
                }}>
                    <Link to="/clash-x-tp-tee">
                    <img src={productImg3} style={{
                        width: '120%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginLeft: '-10%',
                        // marginRight: 'auto',
                        marginTop: '-20px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '16px',
                        marginTop: '-20px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>CLASH X TRAVS PRESENTS<br></br>TEE<br></br><span style={{color: 'white'}}>£25</span></h1>
                </div>

                <div style={{
                    display: 'block',
                    width: '280px',
                    // border: '1px solid green'
                }}>
                    <Link to="/eyes-tee">
                    <img src={productImg4} style={{
                        width: '120%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginLeft: '-10%',
                        // marginRight: 'auto',
                        marginTop: '-20px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '-20px',
                        color: 'white',
                        fontSize: '16px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>EYES T-SHIRT<br></br><span style={{color: 'red'}}>[EXCLUSIVE DROP]</span><br></br><span style={{color: 'white'}}>£25</span></h1>
                </div>

                <div style={{
                    display: 'block',
                    width: '280px',
                    paddingLeft: '40px',
                    // border: '1px solid green'
                }}>
                    <Link to="/og-tee">
                    <img src={productImg} style={{
                        width: '120%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginLeft: '-10%',
                        // marginRight: 'auto',
                        marginTop: '-20px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '-20px',
                        color: 'white',
                        fontSize: '16px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>O.G. T-SHIRT<br></br>£25</h1>
                </div>
                {/* <h1 style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'center',
                    fontFamily: 'Eurostile',
                    lineHeight: '20px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '20px'
                }}>^THE NEW ERA BBK T-SHIRT^<br></br>£30</h1> */}
                <div style={{
                    display: 'none',
                    width: '220px',
                    // border: '1px solid green',
                    marginLeft: '40px'
                }}>
                    <Link to="/glow-in-the-dark">
                    <img src={productImg2} style={{
                        width: '220px',
                        justifyContent: 'center',
                        display: 'flex',
                        // marginLeft: '40px',
                        // marginRight: 'auto',
                        marginTop: '25px',
                    }}/>
                    </Link>
                    <h1 style={{
                        // marginLeft: '40px',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        marginTop: '-20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>GLOW IN THE DARK<br></br>T-SHIRT</h1>
                    <h2 style={{
                        marginRight: 'auto',
                        color: 'red',
                        fontSize: '8px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>[LIMITED]</h2>
                </div>
            </div>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={768}>
            <div className="mainPageDiv" style={{
                backgroundColor: "black",
                backgroundImage: `url(${eyesGraphic})`,
                    backgroundSize: "cover", // Adjust how the image fits the background
                    backgroundSize: '100%',
                    backgroundPosition: "center", // Centers the image
                    backgroundRepeat: "no-repeat", // Prevents tiling
                width: '100%',
                height: '100%',
                top: '0',
                overflowX: 'hidden',
                overflowY: 'scroll'
            }}>
            <div style={{
                marginTop: '10px'
            }}>
                <Header></Header>
            </div>
            <Link to="/">
            <SpinningLogo></SpinningLogo>
            </Link>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '24px',
                textAlign: 'center',
                fontFamily: 'Eurostile',
                fontWeight: '700',
                lineHeight: '30px',
                whiteSpace: 'pre-wrap'
            }}>
                STORE<br></br><span style={{color: 'red', fontSize: '14px'}}>[1] EXCLUSIVE ITEM ADDED</span>
            </h1>
            <div style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                // border: '1px solid green',
                alignItems: 'center',
                paddingBottom: '50px'
                // padding: '20px'
            }}>
                <div style={{
                    display: 'block',
                    width: '280px',
                    padding: '20px',
                    // border: '1px solid green'
                }}>
                    <Link to="/og-tee">
                    <img src={productImg} style={{
                        width: '120%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginLeft: '-10%',
                        // marginRight: 'auto',
                        marginTop: '-20px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '-20px',
                        color: 'white',
                        fontSize: '16px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>O.G. T-SHIRT<br></br>£25</h1>
                </div>
                <div style={{
                    display: 'block',
                    width: '280px',
                    padding: '20px',
                    // border: '1px solid green',
                }}>
                    <Link to="/clash-x-tp-tee">
                    <img src={productImg3} style={{
                        width: '120%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginLeft: '-10%',
                        // marginRight: 'auto',
                        marginTop: '-20px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        color: 'white',
                        fontSize: '16px',
                        marginTop: '-20px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>CLASH X TRAVS PRESENTS<br></br>TEE<br></br><span style={{color: 'white'}}>£25</span></h1>
                </div>
                <div style={{
                    display: 'block',
                    width: '280px',
                    padding: '20px',
                    // border: '1px solid green'
                }}>
                    <Link to="/eyes-tee">
                    <img src={productImg4} style={{
                        width: '120%',
                        justifyContent: 'center',
                        display: 'flex',
                        marginLeft: '-10%',
                        // marginRight: 'auto',
                        marginTop: '-20px',
                        // border: '1px solid green'
                    }}/>
                    </Link>
                    <h1 style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '-20px',
                        color: 'white',
                        fontSize: '16px',
                        textAlign: 'center',
                        fontFamily: 'Eurostile',
                        lineHeight: '20px',
                        whiteSpace: 'pre-wrap',
                        // border: '1px solid green'
                    }}>EYES T-SHIRT<br></br><span style={{color: 'red'}}>[NEW DROP]</span><br></br><span style={{color: 'white'}}>£25</span></h1>
                </div>
            </div>
            </div>
        </MediaQuery>
    </section>
);

export default Store;