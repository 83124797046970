import {React} from "react";
import "../App.css";
import '../index.css';
import MediaQuery from 'react-responsive'

const ProductPage = (props) => (
    <section>
        <MediaQuery minWidth={520}>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '26px',
                textAlign: 'center',
                fontFamily: 'Eurostile',
                lineHeight: '20px',
                whiteSpace: 'pre-wrap'
            }}>
                {props.title}
            </h1>
            <div className="shopContainer" style={{
                display: 'block',
                justifyContent: 'center',
                width: '520px',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                // border: "1px solid green",
                marginTop: '18px'
            }}>
                <img src={props.img} style={{
                    width: '500px',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '-35px',
                    // border: '1px solid green'
                }}/>
                <h2 style={{
                    width: '240px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Eurostile',
                    lineHeight: '16px',
                    fontWeight: '400',
                    whiteSpace: 'pre-wrap',
                    marginTop: '-15px',
                    // border: '1px solid green',
                    textAlign: 'center'
                }}>
                    {props.description}
                </h2>
                <h2 style={{
                    width: '200px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'red',
                    fontSize: '18px',
                    fontFamily: 'Eurostile',
                    lineHeight: '14px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '18px',
                    // border: '1px solid green',
                    textAlign: 'center'
                }}>
                    CHOOSE A SIZE
                </h2>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '440px',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    // marginTop: '-5px'
                    // marginBottom: '40px',
                    // border: '1px solid green',
                }}>
                    <a href={props.smallLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '10px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>SMALL</button>
                    </a>
                    <a href={props.mediumLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '10px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>MEDIUM</button>
                    </a>
                    <a href={props.largeLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '10px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>LARGE</button>
                    </a>
                    <a href={props.xlargeLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '10px',
                        fontFamily: 'Press',
                        marginTop: '8px',
                        whiteSpace: 'nowrap'
                    }}>X LARGE</button>
                    </a>
                    <a href={props.xxlargeLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '10px',
                        fontFamily: 'Press',
                        marginTop: '8px',
                        whiteSpace: 'nowrap'
                    }}>XX LARGE</button>
                    </a>
                </div>
                <div style={{
                        height: '40px',
                        display: 'block',
                        width: '100%'
                    }}>
                </div>
            </div>
        </MediaQuery>
            <MediaQuery maxWidth={520}>
            <h1 style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                fontSize: '26px',
                textAlign: 'center',
                fontFamily: 'Eurostile',
                lineHeight: '20px',
                whiteSpace: 'pre-wrap'
            }}>
                {props.title}
            </h1>
            <div className="shopContainer" style={{
                display: 'block',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                // border: "1px solid green",
                marginTop: '20px'
            }}>
                <img src={props.img} style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '-35px',
                    // border: '1px solid green'
                }}/>
                <h2 style={{
                    width: '240px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Eurostile',
                    lineHeight: '16px',
                    fontWeight: '400',
                    whiteSpace: 'pre-wrap',
                    marginTop: '-15px',
                    // border: '1px solid green',
                    textAlign: 'center'
                }}>
                    {props.description}
                </h2>
                <h2 style={{
                    width: '200px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    color: 'red',
                    fontSize: '18px',
                    fontFamily: 'Eurostile',
                    lineHeight: '14px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '18px',
                    // border: '1px solid green',
                    textAlign: 'center'
                }}>
                    CHOOSE A SIZE
                </h2>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '300px',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    // marginBottom: '40px',
                    // border: '1px solid green',
                }}>
                    <a href={props.smallLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '8px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>SMALL</button>
                    </a>
                    <a href={props.mediumLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '8px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>MEDIUM</button>
                    </a>
                    <a href={props.largeLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '8px',
                        fontFamily: 'Press',
                        marginTop: '8px'
                    }}>LARGE</button>
                    </a>
                    <a href={props.xlargeLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '8px',
                        fontFamily: 'Press',
                        marginTop: '8px',
                        whiteSpace: 'nowrap'
                    }}>X LARGE</button>
                    </a>
                    <a href={props.xxlargeLink} target="_blank" rel="noopener noreferrer">
                    <button style={{
                        height: '25px',
                        backgroundColor: 'red',
                        color: 'black',
                        fontSize: '8px',
                        fontFamily: 'Press',
                        marginTop: '8px',
                        whiteSpace: 'nowrap'
                    }}>XX LARGE</button>
                    </a>
                </div>
                <div style={{
                        height: '40px',
                        display: 'block',
                        width: '100%'
                    }}>
                </div>
            </div>
        </MediaQuery>
    </section>
);

export default ProductPage;